// NPM
import React from "react"

// Shared Components
import CommonHero from 'src/components/Shared/CommonHero'
import InfoColumns from 'src/components/Shared/InfoColumns'
import Partners from 'src/components/Shared/Partners'
import Timeline from 'src/components/Shared/Timeline'
import ProductsSection from 'src/components/Shared/ProductsSection'
import SimpleSection from 'src/components/Shared/SimpleSection';
import WorkTogether from 'src/components/Shared/WorkTogether';

const DecisionTools = ({ sections, teamMembers, advisors }) => {
  return (
    <div className="DecisionTools">
      <CommonHero
        section={sections.find(s => s.uuid === "decisionTools_title")}
      />
      <InfoColumns sections={sections} />
      <Partners section={sections.find(s => s.uuid === "partners")} />
      <Timeline section={sections.find(s => s.uuid === "timeline")} />
      <SimpleSection
        section={sections.find(s => s.uuid === "decisionTools_description")}
      />
      <ProductsSection sections={sections} />
      <WorkTogether section={sections.find(s => s.uuid === "workTogether")} />
    </div>
  )

}

export default DecisionTools