// NPM
import React from 'react'

// Shared components
import Description from 'src/components/Shared/Description'
import CommonLink from 'src/components/Shared/CommonLink'

const SimpleSection = ({ section }) => {
  if(!section) {
    return null
  }

  return (
    <div className="SimpleSection">
      { section.title && <h3 className="small-header">{ section.title }</h3> }

      <Description
        className="SimpleSection__description"
        description={ section.description }
      />

      <CommonLink 
        className="SimpleSection__link"
        link={ section.link }>
          <span className="icon">
            <img src={ require("src/images/icons/arrow-right-white.png").default } />
          </span>
      </CommonLink>
    </div>
  )
}

export default SimpleSection