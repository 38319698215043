// NPM
import React from 'react'

// Components
import Product from './Product'
import WorkTogetherProduct from './WorkTogetherProduct'

const ProductsSection = ({ sections }) => {
  const productsSection = sections.find(s => s.uuid === "ourProducts")

  if(!productsSection) {
    return null
  }

  return (
    <div className="ProductsSection">
      <h3 className="small-header">
        { productsSection.title }
      </h3>

      <div className="ProductsSection__products">
        {
          sections.filter(s => s.uuid === "product").map((product, productIndex) => {
            return (
              <Product
                key={ `Product-${ productIndex }` }
                product={ product }
              />
            )
          })
        }

        <WorkTogetherProduct section={ sections.find(s => s.uuid === "product_workTogether") } />
      </div>
    </div>
  )
      
}

export default ProductsSection