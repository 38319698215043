// NPM
import React from 'react'

// Shared components
import Description from 'src/components/Shared/Description'
import CommonLink from 'src/components/Shared/CommonLink'

const WorkTogetherProduct = ({ section }) => {

  if(!section) {
    return null
  }

  return (
    <div className="WorkTogetherProduct">
      <img
        className="WorkTogetherProduct__icon"
        src={ require("src/images/icons/handshake-icon.png").default }
      />

      <h4 className="WorkTogetherProduct__title">
        { section.title }
      </h4>

      <Description
        className="WorkTogetherProduct__description"
        description={ section.description }
      />

      <CommonLink 
        link={ section.link }
        className="WorkTogetherProduct__link black-button"
      />
    </div>
  )

}

export default WorkTogetherProduct