// NPM
import React from 'react'
import _ from 'lodash'

// Shared components
import Description from 'src/components/Shared/Description'
import CommonLink from 'src/components/Shared/CommonLink'

const Product = ({ product }) => {

  if(!product) {
    return null
  }

  return (
    <div className="Product">
      <div className="Product__image">
        <img 
          src={ _.get(product, "media.0.image.url") }
          alt={ _.get(product, "media.0.imageAlt") }
        />
      </div>
      <div className="Product__content">
        <h4 className="Product__content-title">
          { product.title }
        </h4>

        <Description
          className="Product__content-description"
          description={ product.description }
        />

        <CommonLink
          className="Product__content-link"
          link={ product.link }
        />
      </div>
    </div>
  )
}

export default Product