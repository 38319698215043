// NPM
import React from 'react'
import _ from 'lodash'

// Components
import Description from 'src/components/Shared/Description'
import CommonLink from 'src/components/Shared/CommonLink'

const CommonHero = ({ section }) => {

  if(!section) {
    return null;
  }

  const subText = section.miscText.find(mt => mt.uuid === "hero_subText")

  return (
    <div className="CommonHero">
      <h2 className="CommonHero__title small-header">
        { section.title }
      </h2>

      <Description 
        description={ section.description } 
        className="CommonHero__description"
      />

      {
        subText &&
          <p className="CommonHero__subText">
            { subText.text }
          </p>
      }

      { 
        section.link &&
          <div className="CommonHero__link-holder">
            <CommonLink link={ section.link } className="CommonHero__link">
              <span className="icon">
                <img src={ require("src/images/icons/arrow-right-white.png").default } />
              </span>
            </CommonLink>  
          </div>
      }
    </div>
  )

}

export default CommonHero;